<template>
  <div
    id="user-binding"
  >
    <b-button
      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
      variant="flat-primary"
      class="p-0"
      @click="$router.push({ name: 'switch-account' }).catch(() => {})"
    >
      <feather-icon
        icon="ChevronLeftIcon"
      />
      <span class="align-middle">{{ t('title.Back') }}</span>
    </b-button>
    <hr>
    <b-card class="mb-0">
      <h2 class="text-primary text-center">
        <strong>{{ t('title.Add new member account') }}</strong>
      </h2>
      <b-card-text class="mb-2 text-center">
        <h5>{{ t('title.Add new member account info') }}</h5>
        <!-- already have an account? Sign In -->
      </b-card-text>
      <b-row class="flex justify-content-center">
        <b-col
          md="6"
        >
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="ID Card / Passport No."
              rules="required"
            >
              <label>{{ t('patient.idCard') }}, {{ t('patient.passport') }}</label>
              <b-input-group>
                <b-form-input
                  v-model="idCardValue"
                  :state="errors.length > 0 ? false:null"
                  placeholder="ID Card / Passport No."
                  autocomplete="OFF"
                />
                <b-input-group-append>
                  <b-button
                    variant="outline-primary"
                    @click="getPatientInfo"
                  >
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <validation-observer ref="bindingForm">
        <b-form
          v-if="!checkOTPState"
        >
          <!-- checkProfile -->
          <div
            v-if="checkProfile"
            id="profile-details"
          >
            <b-card
              border-variant="primary"
              bg-variant="transparent"
              class="shadow-none flex justify-content-center"
            >
              <!-- <b-card-header>
                {{ t('title.Profile') }}
              </b-card-header> -->
              <b-card-text>
                <b-row>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <strong>{{ t('patient.hn') }} <h6 class="text-primary"> {{ familyHN }}</h6></strong>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <strong>{{ t('patient.name') }} <h6 class="text-primary"> {{ familyName }}</h6></strong>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <strong>{{ t('patient.telephone number') }} <h6 class="text-primary"> {{ familyPhone }}</h6></strong>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card>
            <b-row class="mb-1">
              <b-col>
                <label>{{ t('patient.relationship') }}</label>
                <b-form-input
                  v-model="relationship"
                  type="text"
                  autocomplete="OFF"
                  :placeholder="t('patient.of this account')"
                />
              </b-col>
            </b-row>
            <small class="text-center">
              {{ t('When u click') }} "{{ t('Button Connect Customer') }}" {{ t('Connect HN') }} {{ t('Accept') }}
              <b-link v-b-modal.termsAndConditionsModal>{{ t('Privacy Policy') }}</b-link> {{ t('for Hospital') }}
            </small>
            <b-row class="mt-2">
              <!-- <b-col cols="6">
                <b-button
                  variant="dark"
                  type="button"
                  @click="$router.push({ name: 'switch-account' })"
                >
                  {{ t('Button Cancel') }}
                </b-button>
              </b-col> -->
              <b-col>
                <b-button
                  block
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
                >
                  {{ t('Button Connect Customer') }}
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-form>

        <!-- OTP -->
        <b-card v-else>
          <validation-observer
            ref="otpForm"
          >
            <h2 class="text-primary text-center">
              <strong class="text-success">OTP</strong>
            </h2>
            <h5 class="text-center">
              {{ t('title.OTP fill') }}
            </h5>
            <h2 class="mt-2 mb-2 text-center">
              <strong>
                ❝ {{ familyPhone.substring(0, 3) }}-{{ familyPhone.substring(3, 6) }}-{{ familyPhone.substring(6) }} ❞
              </strong>
            </h2>
            <b-card-text class="text-center">
              <v-otp-input
                class="flex justify-content-center"
                input-classes="otp-input"
                :num-inputs="6"
                separator=""
                :should-auto-focus="true"
                @on-complete="handleOnComplete"
                @on-change="handleOnChange"
              />
              <h6 class="mt-3">
                {{ t('title.OTP question') }}
              </h6>
              <h6 v-if="countDown>0">
                {{ countDown }}
                <b-spinner
                  small
                  variant="primary"
                />
              </h6>
              <b-button
                variant="link"
                :disabled="countDown>0"
                @click="sendOTP"
              >
                {{ t('title.OTP resend') }}
              </b-button>
              <hr>
              <b-button
                block
                variant="primary"
                type="submit"
                :disabled="validationState"
                @click.prevent="validationOTP"
              >
                <b-spinner
                  v-if="validationState"
                  small
                />
                {{ t('Button Save') }}
              </b-button>
            </b-card-text>
          </validation-observer>
        </b-card>
      </validation-observer>
    </b-card>
    <b-modal
      id="termsAndConditionsModal"
      hide-header
      hide-footer
      size="xl"
      scrollable
      no-close-on-backdrop
    >
      <terms-and-conditions @consent="closeConsent" />
    </b-modal>
  </div>

</template>

<script>
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCardText,
} from 'bootstrap-vue'
import { required } from '@validations'
// import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
// import useJwt from '@/auth/jwt/useJwt'
import OtpInput from '@bachdgvn/vue-otp-input'
import TermsAndConditions from '../authentication/TermsAndConditions.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    TermsAndConditions,
    'v-otp-input': OtpInput,
  },
  directives: {
    Ripple,
  },
  setup() {
    const { t } = useI18nUtils()
    return {
      t,
    }
  },
  data() {
    return {
      emailValue: '',
      hnValue: '',
      idCardValue: '',
      required,
      accountData: JSON.parse(localStorage.getItem('accountData')),
      familyHN: '',
      familyName: '',
      familyCustomerID: '',
      familyPhone: '',
      relationship: '',
      patientData: [],
      checkProfile: false,
      checkOTPState: false,
      token: '',
      countDown: 60,
      validationState: false,
      OPTValue: 0,
    }
  },
  mounted() {
    this.$bvModal.show('termsAndConditionsModal')
  },
  created() {
    // this.emailValue = this.userData.email
    // this.$bvModal.show('termsAndConditionsModal')
  },
  methods: {
    closeConsent(result) {
      if (result === true) {
        this.$bvModal.hide('termsAndConditionsModal')
      } else {
        this.$bvModal.hide('termsAndConditionsModal')
        this.$router.push({ name: 'switch-account' })
      }
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      }
    },
    getPatientInfo() {
      if (this.idCardValue !== '') {
        this.$http({
          url: `http://phrapi.sriphat.com/api/Patient/GetPatientDetail/${this.idCardValue}/null`,
          method: 'GET',
          data: { },
        }).then(({ data }) => {
          if (data.length > 0 && this.idCardValue === data[0].idCard) {
            this.checkProfile = true
            // eslint-disable-next-line prefer-destructuring
            this.patientData = data[0]
            this.familyName = `${data[0].firstName} ${data[0].lastName}`
            this.familyHN = data[0].hn
            this.familyCustomerID = data[0].customerID
            this.familyPhone = data[0].mainContactNumber.trim()
            this.familyPhone = this.familyPhone.replace('-', '')
          } else {
            this.checkProfile = false
            this.$swal({
              title: 'ไม่พบข้อมูล',
              text: 'กรุณาตรวจสอบหมายเลขที่กรอกอีกครั้ง',
              icon: 'warning',
              customClass: {
                confirmButton: 'btn btn-outline-primary',
              },
              buttonsStyling: false,
            })
          }
        }).catch(error => {
          console.log('getPatientInfo', error)
          this.$refs.bindingForm.setErrors(error)
        })
      }
    },
    validationForm() {
      this.$swal({
        title: `${this.$t('are u sure')}`,
        html: `${this.$t('title.OTP Send To Phone')}<br><h3 class="text-success">${this.familyPhone}</h3>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-outline-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          // this.addFamily()
          this.checkOTPState = true
          this.sendOTP()
        }
      })
    },
    addFamily() {
      this.$http({
        url: 'http://phrapi.sriphat.com/api/UserAuthen/NewAccountFamily',
        method: 'POST',
        data: {
          AccountID: this.accountData.accountID,
          HN: this.familyHN,
          CustomerID: this.familyCustomerID,
          FamilyDescription: this.relationship,
        },
      }).then(({ data }) => {
        if (data) {
          // console.log('Reload Data')
          this.$router.push({ name: 'switch-account' })
        }
      }).catch(error => {
        console.log('addFamily', error)
      })
      this.$router.push({ name: 'switch-account' })
    },
    sendOTP() {
      this.$http({
        url: `http://phrapi.sriphat.com/api/UserAuthen/OTPSending/${this.familyPhone}`,
        method: 'GET',
        data: { },
      }).then(({ data }) => {
        console.log(data)
        this.countDown = 60
        this.countDownTimer()
      })
    },
    validationOTP() {
      // console.log(this.OTPValue.length)
      localStorage.removeItem('accountLogin')
      if (this.OTPValue.length === 6) {
        this.validationState = true
        this.$http({
          url: 'http://phrapi.sriphat.com/api/UserAuthen/OTPChecker',
          method: 'POST',
          data: {
            PhoneNumber: this.familyPhone,
            OTPCode: this.OTPValue,
          },
        }).then(({ data }) => {
          console.log(data)
          if (data) {
            this.addFamily()
          } else {
            this.$swal('Error')
          }
        })
      } else {
        console.log('Show Alert Fill OTP')
      }
      this.validationState = false
    },
    handleOnComplete(value) {
      // console.log('OTP: ', value)
      this.OTPValue = value
    },
    handleOnChange(value) {
      this.OTPValue = value
      // console.log('Check OTP: ', value)
    },
  },
}
</script>
<style>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 5px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}
.error {
  border: 1px solid red !important;
}
</style>
